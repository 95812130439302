@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: 0 0% 100%;
  --foreground: 240 10% 3.9%;
  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;
  --primary: 240 5.9% 10%;
  --primary-foreground: 0 0% 98%;
  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;
  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;
  --accent: 240 4.8% 95.9%;
  --accent-foreground: 240 5.9% 10%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;
  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;
  --ring: 240 10% 3.9%;
  --chart-1: 12 76% 61%;
  --chart-2: 173 58% 39%;
  --chart-3: 197 37% 24%;
  --chart-4: 43 74% 66%;
  --chart-5: 27 87% 67%;
  --radius: 0.5rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#scrollable-content,
aside {
  transition: 0.3s all;
}

/* React Tags */
.rc__base {
  position: relative;
  padding: 0.5rem;
  border: 2px solid #afb8c1;
  border-radius: 6px;
  background: #ffffff;
  font-size: 1rem;
  line-height: 1.2;
  cursor: text;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 300px;
}
.rc__active {
  border-color: #4f46e5;
}
.rc__label {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.rc__combobox {
  display: inline-block;
  padding: 0.375rem 0.25rem;
  max-width: 100%;
}
.rc__combobox-input {
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  font-size: inherit;
  line-height: inherit;
  width: 70px !important;
}
.rc__list {
  display: inline;
  padding: 0;
}
.rc__listbox {
  position: absolute;
  z-index: 1;
  top: calc(100% + 5px);
  left: -2px;
  right: -2px;
  max-height: 8.5rem;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid #afb8c1;
  border-radius: 6px;
  box-shadow:
    #0000001a 0 10px 15px -4px,
    #0000000d 0 4px 6px -2px;
}
.rc__listbox-option {
  padding: 0.375rem 0.5rem;
  cursor: pointer;
}

.rc__listbox-option[aria-selected='true']:not(.is-active):after {
  color: #4f46e5;
}
.rc__listbox-option[aria-selected='true']:after {
  content: '✓';
  margin-left: 0.5rem;
}

.rc__listbox-option:not([aria-disabled='true']).is-active {
  background: #4f46e5;
  color: #fff;
}

[role='listbox'] [aria-disabled='true'] {
  opacity: 0.5;
  border-bottom: 1px solid #afb8c1;
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

/* test */
.rodo-selector .dropdown-heading {
  height: auto !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.rodo-selector .dropdown-heading-value {
  white-space: normal !important;
}

.modal-wrapper {
  max-height: 90svh;
  overflow: auto;
}

.rmsc {
  --rmsc-h: 36px !important;
  --rmsc-radius: 6px !important;
}
.item-renderer {
  display: flex !important;
  align-items: start !important;
  gap: 0.25rem !important;
}
.item-renderer input {
  position: relative;
  top: 2px;
}

.rmsc .dropdown-container {
  border-width: 0 !important;
  border-color: rgb(107, 114, 128);
  box-shadow:
    rgb(255, 255, 255) 0px 0px 0px 0px inset,
    rgb(209, 213, 219) 0px 0px 0px 1px inset,
    rgba(0, 0, 0, 0.07) 0px 2px 2px 0px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.loader {
  width: 1rem;
  height: 1rem;
  margin-right: 5px;
  border: 3px solid #efefef;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.notification-loader {
  width: 2rem;
  height: 2rem;
  margin-right: 5px;
  border: 3px solid rgb(7 89 133);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.animated {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.delayed_025s {
  -webkit-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.modal-card > .modal-content {
  margin: 0;
}

.dropdown-content {
  position: relative;
  z-index: 100;
}
#notifications-panel {
  overflow: auto;
  scroll-behavior: smooth;
  scroll-margin: 0;
  scroll-padding: 0;
  scrollbar-color: #c1c1c1 transparent;
  scrollbar-gutter: stable;
  scrollbar-width: thin;
  max-height: 400px !important;
}
